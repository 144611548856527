import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import {
    Box,
    Button,
    Heading,
    Flex,
    Link,
    SimpleGrid,
    Text
} from "@chakra-ui/react";

function getTopBrands(brands) {
    let orderedBrands
    orderedBrands = brands.sort(compare)
    return orderedBrands
}
function compare(a, b) {
    if (a.ad === null || b.ad === null) {
        return 0;
    }
    if (a.ad.length < b.ad.length) {
        return 1;
    }
    if (a.ad.length > b.ad.length) {
        return -1;
    }
    return 0;
}
function cleanBrandsList(links) {
    var cleanLinksArray = []
    for (let index = 0; index < links.length; index++) {
        const element = links[index].node ? links[index].node : links[index];
        if (element && element.ad) {
            cleanLinksArray.push(element)
        }
    }
    // cleanLinksArray = cleanLinksArray.sort(this.compare)
    return cleanLinksArray
}

const BrandsList = (props) => {
    const { brands, universName } = props;
    const topBrands = getTopBrands(cleanBrandsList(props.brands))
    return (
        brands ?
            <Box
                py={{ base: "1rem", md: "2rem" }}
                px={{ base: "1rem", md: "1rem", lg: "2rem" }}
                background="rgba(255,255,255, 0.8)"
            >
                <Flex>
                    <Heading
                        as="h3"
                        mb={5}
                        fontWeight="bold"
                        fontSize={{ base: "13px" }}
                        textTransform="uppercase"
                    >
                        <FormattedMessage id="brands.specialized.boats.builder" values={{ universName: universName }} />&nbsp;:
                        <Button
                            ml={{ lg: '1rem' }}
                            size="xs"
                            as={GatsbyLink}
                            to={process.env.GATSBY_LANG === 'fr' ? '/marques/' : '/brands/'}
                        >
                            <FormattedMessage id="cta.see.all.brands" />
                        </Button>
                    </Heading>
                </Flex>

                <SimpleGrid
                    columns={{ base: 2, md: 3, xl: 4 }}
                >
                    {topBrands.map((edge, i) =>
                        i < props.limite ?
                            <Link as={GatsbyLink}
                                key={`modelItemHP-${i}`}
                                py={{ base: .5, lg: .5 }}

                                color='blue.brand'
                                to={edge.slug}
                                fontSize={{ base: "13px", xl: "15px" }}
                            >
                                {edge.node ? edge.node.name : edge.name}
                                {(edge.ad) ? ` — (${edge.ad.length})` :
                                    null}
                            </Link>
                            : null
                    )}
                </SimpleGrid>
            </Box>
            : null
    )
}
export default BrandsList