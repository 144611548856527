import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import {
    Box,
    Flex,
    Heading,
    Stack,
    Link,
    SimpleGrid,
    useColorModeValue
} from "@chakra-ui/react";

const HomeModelsList = ({ models }) => {
    return (
        <Box
            py={{ base: "1rem", md: "2rem" }}
            px={{ base: "1rem", md: "1rem", lg: "2rem" }}
            background={useColorModeValue('blackAlpha.50', 'whiteAlpha.50')}
        >
            <Flex
                alignItems='center'
                mb='1rem'
                wrap='wrap'
            >
                <Heading
                    as="h2"
                    fontWeight="bold"
                    fontSize={{ base: "13px" }}
                    textTransform="uppercase"
                >
                    <FormattedMessage id="models.list.title.generic" />
                </Heading>
            </Flex>
            <Stack
                spacing={"1rem"}
            >
                <Box
                    overflowY={{ base: "scroll", lg: "auto" }}
                    height="100%"
                >
                    <SimpleGrid
                        columns={{ base: 2, md: 3, xl: 4 }}
                    >
                        {models.edges.map((model, i) =>
                            // i <= 15 ?
                            <Link as={GatsbyLink}
                                fontSize={{ base: "13px", xl: "15px" }}
                                display="block"
                                key={model.node.id}
                                color='blue.brand'
                                to={`${model.node.slug}`}
                                py={{ base: .5, lg: .5 }}
                                // px={{ base: 2, lg: .5 }}
                                aria-label={`Les annonces ${model.node.name}`}
                            >
                                {model.node.name}
                                {(model.node.ad) ? ` — (${model.node.ad.length})` :
                                    null}
                            </Link>
                            // : null
                        )}
                    </SimpleGrid>
                </Box>
            </Stack>
        </Box >
    )
}

export default HomeModelsList