import {
    Box,
    Button,
    Flex,
    Input,
} from '@chakra-ui/react'
import * as React from 'react'
import { BiSearchAlt2 } from 'react-icons/bi';
import { navigate } from 'gatsby'
import { FormattedMessage } from 'react-intl';

export const SearchFakeInput = () => {

    return (
        <Box
            bgColor='primary'
            p={2}
            rounded='md'
            onClick={() => navigate(process.env.GATSBY_LANG === 'fr' ? '/recherche/' : '/search/')}
        >
            <Flex>
                <Flex
                    bgColor='gray.100'
                    alignItems='center'
                    borderLeftRadius={'md'}
                    color='gray.600'
                    px={4}
                    display={{ base: 'none', lg: 'flex' }}
                >
                    <FormattedMessage id='main.research' />
                </Flex>
                <Input
                    position='inherit'

                    ml={{ base: 0, lg: -2 }}
                    // transformStyle='preserve-3d'
                    size='lg'
                    bgColor='white'

                    onFocus={() => navigate(process.env.GATSBY_LANG === 'fr' ? '/recherche/' : '/search/')}
                    placeholder='Moth foiler, Hobie Cat, Mini 6.50'
                />
                <Button
                    position='initial'
                    ml={2}
                    size='lg'
                    fontSize={{ base: '30px', lg: '30px' }}
                >
                    <BiSearchAlt2 />
                </Button>
            </Flex>
        </Box>
    )
}